import { useMutation } from '@tanstack/react-query'
import moment from 'moment'
import { toast } from 'react-toastify'

import api from 'services/api'
import { getGAIds } from 'utils/helpers'
import { SCHEDULE_CART_CONSULTATION_ERRORS } from 'utils/constants'

export const scheduleConsultation = async (details, user_id) => {
  /*eslint-disable-line */
  return new Promise((resolve, reject) => {
    api
      .post(`/patient/${user_id}/schedule_consultation/` /*eslint-disable-line */, details)
      .then(res => {
        const json = {
          ...res.data.content.consultation,
          date: {
            date: res.data.content.consultation.occurrence.date,
            time: moment(
              res.data.content.consultation.occurrence.date +
                res.data.content.consultation.occurrence.time,
              'YYYYMMDDHH:mm'
            ).format('HH:mm')
          },
          checkoutId: res.data.content.checkout_id
        }
        resolve(json)
      })
      .catch(error => {
        reject(error)
      })
  })
}

export const getContractUsesPatient = (user_id, page, params) => {
  /*eslint-disable-line */
  return new Promise((resolve, reject) => {
    const today = moment() /* eslint-disable-line */
    api
      .post(`/patient/${user_id}/get_contract_uses/?page=${page}`, params) /*eslint-disable-line */
      .then(response => {
        resolve(response.data)
      })
      .catch(error => reject(error))
  })
}

export const getConsultationsPatient = (user_id, page, schedule) => {
  /*eslint-disable-line */
  return new Promise((resolve, reject) => {
    const today = moment() /*eslint-disable-line */
    api
      .post(
        `/patient/${user_id}/get_consultations/?page=${page}`,
        schedule
      ) /*eslint-disable-line */
      .then(response => {
        const json = {}
        json.page = response.data.page
        json.count = response.data.count
        json.next = response.data.next
        json.previous = response.data.previous
        json.appointmentsPast = []
        json.appointmentsFuture = []
        response.data.content.forEach(function(e, i) {
          const m = moment(e.occurrence.date, 'YYYYMMDD')
          const m2 = moment(e.occurrence.date + e.occurrence.time, 'YYYYMMDDHH:mm')
          const lastMinute = moment().startOf('day')
          if (
            m.valueOf() >= moment(schedule.date_from, 'YYYYMMDD').valueOf() &&
            m.valueOf() <= moment(schedule.date_to, 'YYYYMMDD').valueOf()
          ) {
            if (m.valueOf() < lastMinute.valueOf()) {
              json.appointmentsPast.push(e)
              json.appointmentsPast[json.appointmentsPast.length - 1].date = {
                weekday: moment.weekdays(m2.day()),
                date: m2.format('DD, MMMM'),
                time: m2.format('HH:mm')
              }
            } else {
              json.appointmentsFuture.push(e)
              json.appointmentsFuture /*eslint-disable-line */[
                json.appointmentsFuture.length - 1
              ].date = {
                weekday: moment.weekdays(m2.day()),
                date: m2.format('DD, MMMM'),
                time: m2.format('HH:mm')
              }
            }
          }
        })
        resolve(json)
      })
      .catch(error => reject(error))
  })
}

export const getConsultationsPatientRaw = (user_id, page, schedule) => {
  /*eslint-disable-line */
  return new Promise((resolve, reject) => {
    const today = moment() /*eslint-disable-line */
    api
      .post(
        `/patient/${user_id}/get_consultations/?page=${page}`,
        schedule
      ) /*eslint-disable-line */
      .then(response => {
        const json = {}
        json.page = response.data.page
        json.count = response.data.count
        json.next = response.data.next
        json.previous = response.data.previous
        json.appointments = response.data.content
        resolve(json)
      })
      .catch(error => reject(error))
  })
}

export const scheduleCartConsultation = async (id, data) =>
  await api.post(`/patient_purchase/${id}/checkoutv2/`, data)

export const getConsultationsPsycho = (user_id, page, schedule) => {
  /*eslint-disable-line */
  return new Promise((resolve, reject) => {
    const today = moment() /*eslint-disable-line */
    api
      .post(
        `/professional/${user_id}/get_consultations/?page=${page}` /*eslint-disable-line */,
        schedule
      )
      .then(response => {
        const json = {}
        json.page = response.data.page
        json.content = []
        json.count = response.data.count
        json.next = response.data.next
        json.previous = response.data.previous
        response.data.content.forEach(function(e, i) {
          const m = moment(e.occurrence.date + e.occurrence.time, 'YYYYMMDDHH:mm')
          e.date = {
            weekday: moment.weekdays(m.day()),
            date: m.format('DD, MMMM'),
            time: m.format('HH:mm')
          }
          e.able_to_cancel =
            m._i >
            moment()
              .add(2, 'days')
              .format('YYYYMMDDHH:mm')
          json.content.push(e)
        })
        resolve(json)
      })
      .catch(error => reject(error))
  })
}

export const getConsultation = (user_id, consultation_id, timezone) => {
  /*eslint-disable-line */
  return new Promise((resolve, reject) => {
    const today = moment().valueOf()
    api
      .post(`/patient/${user_id}/get_consultation/`, {
        /*eslint-disable-line */
        consultation_id,
        timezone
      })
      .then(response => {
        const json = {}
        json.original = response.data.content
        const m = moment(
          json.original.occurrence.date + json.original.occurrence.time,
          'YYYYMMDDHH:mm'
        )
        if (json.original.payment) {
          json.original.date = {
            date: m.format('YYYYMMDD'),
            time: m.format('HH:mm'),
            price: json.original.occurrence.price,
            invoice_id: json.original.payment.invoice_id
          }
        } else {
          json.original.date = {
            date: m.format('YYYYMMDD'),
            time: m.format('HH:mm'),
            price: json.original.occurrence.price
          }
        }
        json.original.isFuture = today < m.valueOf()
        resolve(json)
      })
      .catch(error => reject(error))
  })
}

export const cancel_before_24 = async (user_id, consultation_id) => {
  /*eslint-disable-line */
  return await api.post(`/patient/${user_id}/cancel_consultation_before_24h/`, {
    /*eslint-disable-line */
    consultation_id
  })
}

export const cancel_after_24 = async (user_id, consultation_id, reason) => {
  /*eslint-disable-line */
  return await api.post(`/patient/${user_id}/request_cancel_consultation/`, {
    /*eslint-disable-line */
    consultation_id,
    reason
  })
}

export const cancel_before_24_professional = async (
  user_id /*eslint-disable-line */,
  consultation_id /*eslint-disable-line */
) => {
  return await api.post(
    /*eslint-disable-line */
    `/professional/${user_id}/cancel_consultation_before_24h/` /*eslint-disable-line */,
    { consultation_id }
  )
}

export const cancel_after_24_professional = async (
  user_id /*eslint-disable-line */,
  consultation_id /*eslint-disable-line */,
  reason
) => {
  return await api.post(
    /*eslint-disable-line */
    `/professional/${user_id}/request_cancel_consultation/` /*eslint-disable-line */,
    { consultation_id, reason }
  )
}

export const generateBankSlip = (user_id, id) => {
  /*eslint-disable-line */
  const bankSlipLink = window.open('', '_blank')
  bankSlipLink.document.write('Carregando...')
  return new Promise((resolve, reject) => {
    getConsultation(user_id, id).then(
      data => {
        const invoice_id = data.original.payment.invoice_id /*eslint-disable-line */
        bank_slip(invoice_id).then(
          response => {
            bankSlipLink.location.href = response.data.content.pdf
            resolve()
          },
          err => {
            reject(err)
          }
        )
      },
      err => {
        reject(err)
      }
    )
  })
}

export const bank_slip = async invoiceId => {
  /*eslint-disable-line */
  return await api.get('/bank_slip/' + invoiceId + '/') /*eslint-disable-line */
}

export const getSchedules = async (psycho_id /*eslint-disable-line */) =>
  await api.get(`/professional_schedule/${psycho_id}/get_schedules/`) /*eslint-disable-line */

export const removeSchedule = async (psycho_id, schedule_id /*eslint-disable-line */) =>
  await api.delete(`/professional_schedule/${psycho_id}/remove_schedule/`, {
    /*eslint-disable-line */
    data: { schedule_id }
  })

export const setSchedule = (psycho_id, schedule) => {
  /*eslint-disable-line */
  return new Promise((resolve, reject) => {
    api
      .post(`/professional_schedule/${psycho_id}/set_schedule/`, schedule) /*eslint-disable-line */
      .then(response => {
        resolve()
      })
      .catch(error => reject(error.response.data.log_content))
  })
}

export const changeConsultationPatient = (idPatient, config) =>
  api.post(`/patient/${idPatient}/change_consultation/`, config)

export const changeConsultationPsycho = (idPsycho, config) =>
  api.post(`/professional/${idPsycho}/change_consultation/`, config)

export const reSchedulingConfirm = (
  uid,
  token,
  consultation_id,
  confirm /*eslint-disable-line */
) =>
  api.post(`/rescheduling/confirm/${uid}/${token}/`, {
    consultation_id,
    confirm
  })

export const acceptCancelConsultation = (
  uid,
  token,
  consultation_id /*eslint-disable-line */,
  confirm
) =>
  api.post(`/accept_cancel_consultation/confirm/${uid}/${token}/`, {
    consultation_id,
    confirm
  })

export const getBlockedSchedules = (userId, payload) =>
  api.post(`/patient/${userId}/validate_consultation_date/`, payload)

export const singleClickPurchase = (patientId, data) =>
  api.post(`/patient_purchase/${patientId}/one_click_buy/`, { ...data, ...getGAIds() })

export const useScheduleCartAppointment = (patientId, options = {}) =>
  useMutation(
    async data => {
      const response = await scheduleCartConsultation(patientId, data)
      return response?.data?.content || {}
    },
    {
      ...options,
      onError: error => {
        const errorCode = error?.response?.data?.log_content?.error_code

        toast.error(
          SCHEDULE_CART_CONSULTATION_ERRORS?.[errorCode] ||
            SCHEDULE_CART_CONSULTATION_ERRORS.default,
          {
            autoClose: 5000
          }
        )
      },
      onSuccess: async (content, variables, context) => {
        await options?.onSuccess?.(content, variables, context)
        localStorage.setItem('checkoutData', JSON.stringify({ content }))
      }
    }
  )

export const useSingleClickPurchase = (patientId, options) =>
  useMutation(async data => {
    const response = await singleClickPurchase(patientId, data)
    return response?.data?.content
  }, options)
