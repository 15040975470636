import React, { useEffect } from 'react'
import dynamic from 'next/dynamic'
import styled from 'styled-components'
import Alert from './FooterAlertHome'
import { Row, Column } from '../../v2/Grid'
import { Text } from '../../v2/Text'
import { Accordion } from '../../v2/Accordion'
import { DownloadAppSection } from '../../v2/DownloadAppSection'
import {
  LinkedinWithoutBackground,
  TwitterWithoutBackground,
  FacebookWithoutBackground,
  Youtube,
  Instagram
} from '../../v2/Icons'
import { LinkComponentNoPrefetch } from '../../v2/Link'
import { NextImage } from 'components/v2/NextImage'
import { theme } from '../../../utils/theme'
import { getCurrentYear } from 'utils/helpers'
import { getProfile } from 'services/auth'

import _LOCATIONS_FOOTER from '../../../utils/seo/footer.json'
import _SYMPTOMS_FOOTER from '../../../utils/seo/symptoms-footer-online.json'
import _SKILLS_FOOTER from '../../../utils/seo/skills-online.json'

const LazyImage = dynamic(() => import('../LazyImage'), {
  ssr: false
})

const Intercom = dynamic(() => import('../Intercom'))

const user = getProfile()

const handleVittudeMatchCache = () => {
  sessionStorage.removeItem('psychologistResult')
  localStorage.removeItem('@vittude:match-maker')
}

const FooterIntercomClickableHome = () => {
  let LOCATIONS_FOOTER = JSON.parse(_LOCATIONS_FOOTER)
  let SYMPTOMS_FOOTER = JSON.parse(_SYMPTOMS_FOOTER)
  let SKILLS_FOOTER = JSON.parse(_SKILLS_FOOTER)

  return (
    <footer className='margin-footer' id='footer'>
      {user?.role !== 'professional' && <DownloadAppSection />}
      <Alert />
      <Column>
        <Row
          maxWidth={['100%', '720px', '1064px']}
          minWidth={['100%', '720px', '1064px']}
          margin='0 auto'
          alignItems='center'
          py={['24px', '100px']}
          px={['17px', '10px']}
          flexWrap='wrap'
        >
          <RowFooter>
            <ColumnFooter>
              <Column sm='12' md='12' className='info-vittude-footer'>
                <FooterTitle variant='small' fontWeight='bold'>
                  A Vittude
                </FooterTitle>
                <LinkComponentFooter
                  href='/quem-somos'
                  target='_blank'
                  label='Quem Somos'
                  rel='noreferrer'
                />
                {/* <LinkComponentFooter href='/blog' label='Blog' /> */}
                <LinkComponentFooter
                  href='https://vittude.gupy.io'
                  target='_blank'
                  label='Seja um Vittuder'
                  rel='noreferrer'
                />
                <LinkComponentFooter href='/faq' label='Perguntas Frequentes' />
                <LinkComponentFooter
                  href='https://contatoseguro.com.br/vittude/'
                  target='_blank'
                  aria-label='Canal de Ética'
                  rel='noopener noreferrer'
                  label='Canal de Ética'
                />
                <LinkComponentFooter
                  href='http://site.cfp.org.br/wp-content/uploads/2012/07/codigo_etica.pdf'
                  target='_blank'
                  aria-label='Código de Ética do Psicólogo'
                  rel='noopener noreferrer'
                  label='Código de Ética do Psicólogo'
                />
                <LinkComponentFooter
                  href='http://site.cfp.org.br/'
                  aria-label='Conselho Federal de psicologia'
                  target='_blank'
                  rel='noopener noreferrer'
                  label='Conselho Federal de Psicologia'
                />
                <LinkComponentFooter
                  href='http://www.crpsp.org/site/'
                  aria-label='Conselho Regional de psicologia'
                  target='_blank'
                  rel='noopener noreferrer'
                  label='Conselho Regional de Psicologia'
                />
                <LinkComponentFooter
                  href='https://site.cfp.org.br/wp-content/uploads/2018/05/RESOLU%C3%87%C3%83O-N%C2%BA-11-DE-11-DE-MAIO-DE-2018.pdf'
                  target='_blank'
                  rel='noopener noreferrer'
                  aria-label='Resolução CFP 011/2018'
                  label='Resolução CFP 011/2018'
                />

                <TextLinkable href='/termos-paciente' label='Termos de uso' />
                <TextLinkable href='/privacidade' label='Política de privacidade' />
                <TextLinkable href='/politica-de-cookies' label='Política de Cookies' />
                <TextLinkable href='/contato' label='Fale Conosco' />
              </Column>
            </ColumnFooter>
            <ColumnFooter>
              <FooterTitle variant='small' fontWeight='bold'>
                Para você
              </FooterTitle>
              <Column sm='12' md='12' className='recommended-links-footer'>
                <LinkComponentFooter
                  href='/encontre-seu-psicologo'
                  label='Vittude Match'
                  onClick={() => handleVittudeMatchCache()}
                />
                <Column sm='12' md='12' mt='35px' className='recommended-links-footer'>
                  <Text
                    variant='small'
                    lineHeight='28px'
                    fontWeight='bold'
                    mb='14px'
                    textAlign='left'
                  >
                    Para empresas
                  </Text>
                  <LinkComponentFooter
                    href='https://www.vittude.com/empresas'
                    label='Vittude Corporate'
                    target='_blank'
                    rel='noreferrer'
                  />
                  <LinkComponentFooter
                    href='https://www.vittude.com/empresas/blog'
                    label='Blog Corporate'
                  />
                  {/*<LinkComponentFooter
                    href='https://landing.vittude.com/indique-uma-empresa/'
                    target='_blank'
                    label='Indique sua empresa'
                    rel='noreferrer'
                  />*/}
                </Column>
                <Column sm='12' md='12' mt='32px' className='recommended-links-footer'>
                  <Text
                    variant='small'
                    lineHeight='28px'
                    fontWeight='bold'
                    textAlign='left'
                    mb='14px'
                  >
                    Para psicólogos
                  </Text>
                  <LinkComponentFooter href='/sou-psicologo' label='Faça parte do time' />
                  <LinkComponentFooter href='/termos-psicologos' label='Termos de Uso' />
                </Column>
              </Column>
            </ColumnFooter>
            <ColumnFooter>
              <FooterTitle variant='small' fontWeight='bold'>
                Psicólogos por especialidades
              </FooterTitle>
              <Column sm='12' md='12' className='recommended-links-footer'>
                {SYMPTOMS_FOOTER.results.slice(0, 11).map(symptom => (
                  <LinkComponentFooter
                    key={symptom.slug}
                    href={`/psicologos-online/${symptom.slug}`}
                    alt={`Psicólogos especializados em ${symptom.description}`}
                    label={symptom.description}
                  />
                ))}
                <TextLinkable href='/especialidades' label='Ver outras especialidades →' mb='8px' />
              </Column>
            </ColumnFooter>
            <ColumnFooter>
              <FooterTitle variant='small' fontWeight='bold'>
                Psicólogos por abordagens
              </FooterTitle>
              <Column sm='12' md='12' className='recommended-links-footer'>
                {SKILLS_FOOTER.results.slice(0, 11).map(skill => (
                  <LinkComponentFooter
                    key={skill.slug}
                    href={`/psicologos-online/${skill.slug}`}
                    alt={`Psicólogos especializados em ${skill.description}`}
                    label={skill.description}
                  />
                ))}
                <TextLinkable href='/abordagens' label='Ver outras abordagens →' mb='8px' />
              </Column>
            </ColumnFooter>
            <ColumnFooter>
              <FooterTitle variant='small' fontWeight='bold'>
                Psicólogos por cidades
              </FooterTitle>
              <Column sm='12' md='12' className='recommended-links-footer'>
                {LOCATIONS_FOOTER.content.slice(0, 11).map(city => (
                  <LinkComponentFooter
                    key={city.slug}
                    href={`/busca?address=${city.name}&lat=${city.latitude}&long=${city.longitude}&consultation=presencial`}
                    alt={`Psicólogos em ${city.name}`}
                    label={city.name}
                  />
                ))}
                <TextLinkable href='/cidades' label='Ver outras regiões →' mb='8px' />
              </Column>
            </ColumnFooter>
          </RowFooter>
          <RowFooterMobile>
            <ColumnFooter>
              <AccordionFooter label='A Vittude' chevronColor={theme.colors.black}>
                <AccordionColumn>
                  <LinkComponentFooter
                    href='/quem-somos'
                    target='_blank'
                    label='Quem Somos'
                    rel='noreferrer'
                  />
                  {/* <LinkComponentFooter href='/blog' label='Blog' /> */}
                  <LinkComponentFooter
                    href='https://vittude.gupy.io'
                    target='_blank'
                    label='Seja um Vittuder'
                    rel='noreferrer'
                  />
                  <LinkComponentFooter href='/faq' label='Perguntas Frequentes' />
                  <LinkComponentFooter
                    href='https://contatoseguro.com.br/vittude/'
                    target='_blank'
                    aria-label='Canal de Ética'
                    rel='noopener noreferrer'
                    label='Canal de Ética'
                  />
                  <LinkComponentFooter
                    href='http://site.cfp.org.br/wp-content/uploads/2012/07/codigo_etica.pdf'
                    target='_blank'
                    aria-label='Código de Ética do Psicólogo'
                    rel='noopener noreferrer'
                    label='Código de Ética do Psicólogo'
                  />
                  <LinkComponentFooter
                    href='http://site.cfp.org.br/'
                    aria-label='Conselho Federal de psicologia'
                    target='_blank'
                    rel='noopener noreferrer'
                    label='Conselho Federal de Psicologia'
                  />
                  <LinkComponentFooter
                    href='http://www.crpsp.org/site/'
                    aria-label='Conselho Regional de psicologia'
                    target='_blank'
                    rel='noopener noreferrer'
                    label='Conselho Regional de Psicologia'
                  />
                  <LinkComponentFooter
                    href='https://site.cfp.org.br/wp-content/uploads/2018/05/RESOLU%C3%87%C3%83O-N%C2%BA-11-DE-11-DE-MAIO-DE-2018.pdf'
                    target='_blank'
                    rel='noopener noreferrer'
                    aria-label='Resolução CFP 011/2018'
                    label='Resolução CFP 011/2018'
                  />
                  <TextLinkable href='/termos-paciente' label='Termos de uso' mt='16px' />
                  <TextLinkable href='/privacidade' label='Política de privacidade' />
                  <TextLinkable href='/politica-de-cookies' label='Política de Cookies' />
                  <TextLinkable href='/contato' label='Fale Conosco' />
                </AccordionColumn>
              </AccordionFooter>
              <AccordionFooter label='Para você' chevronColor={theme.colors.black}>
                <AccordionColumn>
                  <LinkComponentFooter href='/encontre-seu-psicologo' label='Vittude Match' />
                </AccordionColumn>
              </AccordionFooter>
              <AccordionFooter label='Para empresas' chevronColor={theme.colors.black}>
                <AccordionColumn>
                  <LinkComponentFooter
                    href='https://www.vittude.com/empresas'
                    label='Vittude Corporate'
                    target='_blank'
                    rel='noreferrer'
                  />
                  <LinkComponentFooter
                    href='https://www.vittude.com/empresas/blog'
                    label='Blog Corporate'
                  />
                  {/*<LinkComponentFooter
                    href='https://landing.vittude.com/indique-uma-empresa/'
                    target='_blank'
                    label='Indique sua empresa'
                    rel='noreferrer'
                  />*/}
                </AccordionColumn>
              </AccordionFooter>
              <AccordionFooter label='Para psicólogos' chevronColor={theme.colors.black}>
                <AccordionColumn>
                  <LinkComponentFooter href='/sou-psicologo' label='Faça parte do time' />
                  <LinkComponentFooter href='/termos-psicologos' label='Termos de Uso' />
                </AccordionColumn>
              </AccordionFooter>
              <AccordionFooter label='Psicólogos por abordagem' chevronColor={theme.colors.black}>
                <AccordionColumn>
                  {SKILLS_FOOTER.results.slice(0, 10).map(skill => (
                    <div key={`/psicologos-online/${skill.slug}`} className='text-left'>
                      <LinkComponentFooter
                        href={`/psicologos-online/${skill.slug}`}
                        alt={`Psicólogos especializados em ${skill.description}`}
                        label={skill.description}
                      />
                    </div>
                  ))}
                </AccordionColumn>
                <TextLinkable href='/abordagens' label='Ver outras abordagens →' mb='8px' />
              </AccordionFooter>
              <AccordionFooter
                label='Psicólogos por especialidade'
                chevronColor={theme.colors.black}
              >
                <AccordionColumn>
                  {SYMPTOMS_FOOTER.results.slice(0, 10).map(symptom => (
                    <div key={`/psicologos-online/${symptom.slug}`} className='text-left'>
                      <LinkComponentFooter
                        href={`/psicologos-online/${symptom.slug}`}
                        alt={`Psicólogos especializados em ${symptom.description}`}
                        label={symptom.description}
                      />
                    </div>
                  ))}
                </AccordionColumn>
                <TextLinkable href='/especialidades' label='Ver outras especialidades →' mb='8px' />
              </AccordionFooter>
              <AccordionFooter label='Psicólogos por cidade' chevronColor={theme.colors.black}>
                <AccordionColumn>
                  {LOCATIONS_FOOTER.content.slice(0, 10).map(city => (
                    <div
                      key={`/busca?address=${city.name}&lat=${city.latitude}&lng=${city.longitude}&consultation=presencial`}
                      className='text-left'
                    >
                      <LinkComponentFooter
                        href={`/busca?address=${city.name}&lat=${city.latitude}&lng=${city.longitude}&consultation=presencial`}
                        alt={`Psicólogos em ${city.name}`}
                        label={city.name}
                      />
                    </div>
                  ))}
                </AccordionColumn>
                <TextLinkable href='/cidades' label='Ver outras regiões →' mb='8px' />
              </AccordionFooter>
            </ColumnFooter>
          </RowFooterMobile>
          <PaymentInfoRow>
            <PaymentInfoColumn>
              <Text
                variant='small'
                lineHeight='28px'
                fontWeight='bold'
                textAlign='left'
                color='#0a142f'
                mb='4px'
                mt={['24px', '0px']}
              >
                Formas de pagamento
              </Text>
              <NextImage
                width={265}
                height={35}
                src='/static/images/meios-de-pagamento.png'
                alt='logo dos meios de pagamento'
              />
            </PaymentInfoColumn>
            <PaymentInfoColumn>
              <Text
                variant='small'
                lineHeight='28px'
                fontWeight='bold'
                textAlign='left'
                color='#0a142f'
                mb='12px'
                mt={['24px', '0px']}
              >
                Ambiente Seguro
              </Text>
              <SafeEnvironmentLogos>
                <NextImage
                  width={70}
                  height={25}
                  src='/static/images/pci-dss-compliant.png'
                  alt='logo pci-dss-compliant'
                />
                <NextImage
                  width={88}
                  height={25}
                  src='/static/images/google-safe-browsing.png'
                  alt='logo google-safe-browsing'
                />
              </SafeEnvironmentLogos>
            </PaymentInfoColumn>
          </PaymentInfoRow>
          <Row
            maxWidth={['100%', '720px', '1064px']}
            minWidth={['100%', '720px', '1064px']}
            margin='0 auto'
            alignItems='center'
            justifyContent='space-between'
            py='15px'
            flexWrap='wrap'
            borderTop='1px solid #ececec'
            flexDirection={['column', 'row']}
          >
            <Text
              color='#7F8C8D'
              variant='small'
              lineHeight='38px'
              mb='6px'
              display={['none', 'block']}
            >
              Copyright © {getCurrentYear()} Vittude
            </Text>

            <LazyImage
              width={130}
              height={100}
              src={{
                webp: '/static/images/logo-vittude-v2.svg',
                png: '/static/images/logo-vittude-v2.svg'
              }}
              alt='Logo vittude footer'
            />
            <SocialMediaIcons>
              <ImageLinkableFooter
                href='https://www.instagram.com/vittude'
                icon={<Instagram size='12px' color='#0A142F' />}
                target='_blank'
                rel='noreferrer'
              />
              <ImageLinkableFooter
                href='https://www.youtube.com/vittude'
                icon={<Youtube width='16px' color='#0A142F' />}
                target='_blank'
                rel='noreferrer'
              />
              <ImageLinkableFooter
                href='https://br.linkedin.com/company/vittude'
                icon={<LinkedinWithoutBackground size='12px' />}
                target='_blank'
                rel='noreferrer'
              />
              <ImageLinkableFooter
                href='https://www.facebook.com/vittudebr/'
                icon={<FacebookWithoutBackground size='7px' />}
                target='_blank'
                rel='noreferrer'
              />
              <ImageLinkableFooter
                href='https://twitter.com/vittude?lang=en'
                icon={<TwitterWithoutBackground size='13px' />}
                target='_blank'
                rel='noreferrer'
              />
            </SocialMediaIcons>
            <Text
              color='#7F8C8D'
              variant='small'
              lineHeight='38px'
              mb='6px'
              display={['block', 'none']}
            >
              Copyright © {getCurrentYear()} Vittude
            </Text>
          </Row>
        </Row>
      </Column>
      <Intercom />
    </footer>
  )
}

export default FooterIntercomClickableHome

const RowFooter = styled(Row)`
  flex-wrap: wrap;
  min-width: 100%;
  justify-content: space-between;
  align-items: flex-start;

  @media (max-width: 640px) {
    display: none;
  }
`

const FooterTitle = styled(Text)`
  line-height: 28px;
  margin-bottom: 14px;
  text-align: left;
  color: #0a142f;
`

const RowFooterMobile = styled(Row)`
  flex-wrap: wrap;
  min-width: 100%;
  justify-content: space-between;
  align-items: flex-start;

  @media (min-width: 640px) {
    display: none;
  }
`

const PaymentInfoRow = styled(Row)`
  flex-wrap: wrap;
  min-width: 100%;
  justify-content: flex-start;
  align-items: flex-start;

  p {
    text-align: left;
  }

  @media (min-width: 640px) {
    padding-top: 71px;
    margin-bottom: 36px;
  }

  @media (max-width: 639px) {
    justify-content: center;

    p {
      text-align: center;
    }
  }
`

const PaymentInfoColumn = styled(Column)`
  @media (min-width: 640px) {
    margin-right: 160px;
  }
`

const SafeEnvironmentLogos = styled(Row)`
  justify-content: space-between;

  @media (min-width: 640px) {
    width: 230px;
  }

  @media (max-width: 639px) {
    width: 190px;
    margin-bottom: 30px;
  }
`

const AccordionFooter = styled(Accordion)`
  border-bottom: 1px solid #ececec;

  div {
    border: none;
    box-shadow: none;
    margin-bottom: 0px;
  }

  svg {
    fill: red;
  }
`

const AccordionColumn = styled(Column)`
  justify-content: space-between;
  align-items: flex-start;
  background-color: #fff;
  flex-wrap: wrap;
  min-width: 100%;

  @media (min-width: 640px) {
    padding: 20px 10px;
  }

  @media (max-width: 639px) {
    padding: 0px 10px 8px 10px;
  }
`

const ColumnFooter = styled(Column)`
  padding-bottom: 30px;

  @media (max-width: 640px) {
    padding-bottom: 0px;
  }
`

const LinkComponentFooter = styled(LinkComponentNoPrefetch)`
  text-align: left;
  color: #7f8c8d;
  cursor: pointer;
  text-decoration: none;
  margin-bottom: 12px;
  line-height: 17px;
  font-weight: 400;
  font-size: 14px;
`

const ImageLinkableFooter = styled(LinkComponentNoPrefetch)`
  width: 35px;
  height: 35px;
  border: 1.5px solid rgba(0, 10, 20, 0.18);
  border-radius: 50%;
  background: transparent;
  justify-content: center;
`

const TextLinkable = styled(LinkComponentNoPrefetch)`
  color: #0a142f;
  text-decoration: none;
  font-size: 14px;
  line-height: 28px;
  font-weight: bold;
  text-align: left;
`

const SocialMediaIcons = styled(Row)`
  justify-content: space-between;
  align-items: center;
  width: 243px;
  margin: 0px;

  @media (max-width: 639px) {
    margin: 20px;
  }
`
